.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.react-bs-table-container {
  margin: 5px 10px;
}

.tr-string-example,
.th-string-example {
  font-style: oblique;
  color: red;
  background-color: aquamarine;
}

.tr-function-example {
  font-weight: bold;
  background-color: aquamarine;
}

.td-header-string-example {
  font-style: oblique;
  color: red;
  background-color: aquamarine;
}

.td-column-string-example {
  color: blue;
  background-color: antiquewhite;
}

.td-column-function-odd-example {
  color: blue;
  background-color: antiquewhite;
}

.td-column-function-even-example {
  color: red;
  background-color: yellowgreen;
}

.my-header-class {
  background-color: #0066ff;
}

.my-body-class {
  background-color: #cc00ff;
}

.my-container-class {
  border: #0000ff 2.5px solid;
}

.my-table-container-class {
  border: #ffbb73 5px solid;
}

.class-for-editing-cell {
  background-color: rgb(166, 255, 115);
  color: rgb(166, 255, 115);
}
.class-for-Start {
  background-color: beige;
}
.class-for-Quote {
  background-color: beige;
}
.class-for-Client {
  background-color: beige;
}
.class-for-Confirmed {
  background-color: #4caf50;
}
.class-for-Load {
  background-color: #f7fff7;
}
.class-for-Loaded {
  background-color: #4caf50;
}
.class-for-Rejected {
  background-color: #ffebee;
}

.class-for-intruck {
  background-color: #fff59d;
}

.class-red {
  color: red;
}
.class-for-Draft {
  background-color: #e8f5e9;
}

.class-for-ex {
  font-weight: Bold;
  background-color: #e8f5e9;
}

.class-for-ex-cmr {
  font-weight: Bold;
  background-color: #a5d6a7;
}

.class-for-cmr {
  background-color: #c8e6c9;
}

.class-for-wrong {
  background-color: red;
}

.class-checked-fees {
  font-style: italic;
  font-weight: bolder;
}
.class-inc-ch {
  font-style: italic;
  font-weight: bolder;
}
.e-grid .e-headercell.aaa {
  background-color: #2382c3;
  color: white;
  user-select: text;
}
.e-grid .e-headercelldiv {
  border: 0 none;
  display: block;
  font-size: 13px;
  font-weight: Bold;
  height: 29px;
  line-height: 29px;
  margin: -5px;
  overflow: hidden;
  padding: 0 0.4em;
  text-align: left;
}

.e-input-group .e-input-group-icon,
.e-input-group.e-control-wrapper .e-input-group-icon {
  -ms-flex-align: center;
  align-items: center;
  border: 0 solid;
  border-width: 0;
  box-sizing: content-box;
  cursor: pointer;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 4px;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 0.5;
  height: 8px;
  min-width: 8px;
  padding: 0;
  text-align: center;
  border: 1px solid;
  border-bottom: 0;
  border-collapse: collapse;
  border-top: 0;
}
.table,
.table th,
.table td {
  border: 1px solid black;
  padding: 5px;
}
.e-grid.e-wrap .e-rowcell,
.e-grid.e-wrap .e-columnheader .e-stackedheadercelldiv,
.e-grid.e-wrap .e-columnheader .e-headercelldiv {
  height: Auto;
  line-height: 18px;
  overflow-wrap: break-word;
  text-overflow: clip;
  white-space: pre;
  word-wrap: break-word;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  border: 3px solid green;
}
