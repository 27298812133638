/*========== NORMALIZE CSS ==========*/
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Roboto", Arial, sans-serif;
}
main {
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
/*========== END NORMALIZE CSS ==========*/

/*========== ALL PAGES ==========*/
*,
*:before,
*:after {
  box-sizing: border-box;
}
a {
  color: #2476cc;
}
a:hover,
a:focus {
  color: #3b8ee4;
}
p {
  margin: 15px 0;
  line-height: 1.4em;
}
h1 {
  font-weight: 500;
}
h2 {
  font-weight: 400;
}
h3 {
  font-weight: 300;
}
.container-80 {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
ul {
  list-style: none;
}
/*========== END ALL PAGES ==========*/

/*========== BUTTON STYLE ==========*/
.btn-orange {
  display: inline-block;
  color: white;
  background: #f16622;
  padding: 15px;
  min-width: 180px;
  font-size: 20px;
  text-decoration: none;
  text-align: center;
  transition: 0.3s ease-out;
}
.btn-orange:hover {
  background: rgb(224, 96, 32);
  color: white;
}
/*========== END BUTTON STYLE ==========*/

/*========== HEADER ==========*/
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 90%;
  margin: 15px auto;
}
.header-left .logo {
  text-decoration: none;
  display: block;
}
.header-left .logo img {
  max-width: 180px;
}
.header-right ul {
  display: flex;
  margin: 0;
}
.header-right ul li a {
  display: inline-block;
  text-decoration: none;
  padding: 20px 40px;
  font-size: 18px;
  font-weight: 400;
  color: #616161;
  transition: 0.3s ease-out;
}
.header-right ul li a:hover {
  color: #f16622;
}
/*========== END HEADER ==========*/

/*========== HOME CONTENT ==========*/
.slider-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10% 10%;
  background: url(./images/banner.jpg) center no-repeat;
  background-size: cover;
  height: 580px;
}
.slider-content {
  max-width: 80%;
  margin: auto;
}
.slider-content h1 {
  display: none;
  color: #fff;
  text-align: center;
  font-size: 56px;
  line-height: 1.3em;
  color: black;
}
.slider-content h1 > span {
  color: #f16622;
  display: inline-block;
}
.slider-button {
  text-align: center;
}
.slider-button a {
  display: inline-block;
  margin: 0 10px;
}
.download {
  background: url(./images/appStore-playMarket.png) center no-repeat;
  height: 56px;
}
.download-apps {
  background-position: 0 0;
  width: 167px;
}
.download-playm {
  background-position: -176px 0;
  width: 156px;
}
.home-item {
  padding: 4% 0;
}
.home-item h2 {
  text-align: center;
  margin-bottom: 4%;
  font-size: 36px;
  color: #f16622;
}
.home-item-2 {
  background: #f2f3f7;
}
.about {
  display: flex;
  align-items: flex-start;
}
.about > div {
  flex: 1;
}
.about-left {
  margin-right: 15px;
}
.about-left p {
  font-size: 18px;
}
.about-right {
  margin-left: 15px;
}
.about-right img {
  max-width: 100%;
  display: inline-block;
}
.services {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.services > div {
  flex: 1;
  text-align: center;
  margin: 0 20px 20px;
}
.services div img {
  max-width: 100%;
  height: auto;
}
.contact {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.contact > div {
  flex: 1;
}
.contact-left {
  margin-right: 15px;
}
.contact-left iframe {
  width: 100%;
}
.contact-right {
  margin-left: 15px;
}
.contact-right > div {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.contact-right > div > svg {
  margin-right: 10px;
}
.contact-right > div > p {
  margin: 0;
}
.contact-right > div > p > a {
  text-decoration: none;
  display: inline-block;
}
/*========== END HOME CONTENT ==========*/

/*========== FOOTER ==========*/
footer {
  background: #1d1d1d;
  padding: 20px 0;
}
.footer-container {
  max-width: 90%;
  margin: auto;
}
footer p {
  text-align: center;
  color: #fff;
}
/*========== END FOOTER ==========*/

/*========== SCROLL ==========*/
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: transparent;
  margin: 5px;
}
::-webkit-scrollbar-thumb {
  background: rgba(51, 51, 51, 0.6);
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(51, 51, 51, 0.8);
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*========== END SCROLL ==========*/
